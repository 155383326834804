<template>
	<div class="casino-basic-details">
		<fieldset>
			<legend>Basic Details for {{ casinoInfo?.name }}</legend>
			<h2 v-if="!casinoInfo?.isEnabled" class="disabled white-text red-bg">{{ casinoInfo?.name }} is Disabled</h2>
			<h3>{{ casinoInfo?.description }}</h3>
			<img
				class="logo-image"
				v-if="casinoInfo?.logoUrl"
				:src="casinoInfo?.logoUrl"
				:alt="casinoInfo?.name"
				:title="casinoInfo?.name"
			/>
			<p class="website" v-if="casinoInfo?.websiteUrl">
				website:
				<a :href="casinoInfo?.websiteUrl" :title="casinoInfo?.name" target="_blank">{{
					casinoInfo?.websiteUrl
				}}</a>
			</p>
			<div class="available-games">
				<div class="playable">
					<div v-if="casinoInfo?.availableGameTitles?.length > 0">
						<h4>Playable Games</h4>
						<ul>
							<li v-for="(title, index) in casinoInfo?.availableGameTitles" :key="index">{{ title }}</li>
						</ul>
					</div>
					<h4 v-else>No Playable Games at this casino</h4>
				</div>
				<div class="other-info">
					<div v-if="casinoInfo?.playableLocations.length > 0">
						<h4>Playable Locations</h4>
						<ul>
							<li v-for="(location, index) in casinoInfo?.playableLocations" :key="index">
								{{ location }}
							</li>
						</ul>
					</div>
					<h4 v-else>No location restrictions set at this casino</h4>
					<h4>Bingo Card Policy: {{ casinoInfo.gameSettings.bingoCardPolicy }}</h4>
					<h4>In-Game Credits Example: {{ inGameCurrencyTool.formatCurrency(1234.56, systemMinorWholeOrFull) }}</h4>
					<h4>
						In-Game Minor Credits Example: {{ inGameCurrencyTool.formatCurrency(0.56, systemMinorWholeOrFull) }}
					</h4>
					<h4 v-if="casinoInfo?.currencyInfo?.nameSingular || casinoInfo.currencyInfo.namePlural">
						Currency Name:
						<span v-if="casinoInfo?.currencyInfo?.nameSingular"
							>{{ casinoInfo.currencyInfo.nameSingular }}&nbsp;</span
						>
						<span v-if="casinoInfo?.currencyInfo?.namePlural"
							>({{ casinoInfo.currencyInfo.namePlural }})</span
						>
					</h4>
					<h4 v-if="casinoInfo?.currencyInfo?.minorNameSingular || casinoInfo?.currencyInfo?.minorNamePlural">
						Minor Currency Name:
						<span v-if="casinoInfo?.currencyInfo?.minorNameSingular"
							>{{ casinoInfo.currencyInfo.minorNameSingular }}&nbsp;</span
						>
						<span v-if="casinoInfo?.currencyInfo?.minorNamePlural"
							>({{ casinoInfo.currencyInfo.minorNamePlural }})</span
						>
					</h4>
					<h4 v-if="casinoInfo?.currencyInfo?.iso4217Code">
						ISO 4217 Code: {{ casinoInfo.currencyInfo.iso4217Code }}
					</h4>
				</div>
			</div>
		</fieldset>
	</div>
</template>

<script>
export default {
	name: "CasinoBasicDetails",
	props: {
		adminState: Object,
		casinoList: Array,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			casinoInfo: this.casinoList?.filter((casino) => casino.id === this.adminState?.casinoId)[0],
			systemMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
			inGameMinorWholeOrFull: this.systemCurrencyTool.displayType.minorWholeOrFull,
		};
	},
	activated() {
		this.casinoInfo = this.casinoList?.filter((casino) => casino.id === this.adminState?.casinoId)[0];
	},
	watch: {
		casinoList: {
			handler() {
				this.casinoInfo = this.casinoList?.filter((casino) => casino.id === this.adminState?.casinoId)[0];
			},
			deep: true,
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.website {
	text-align: center;
	overflow-wrap: anywhere;
}

.logo-image {
	margin: auto;
	display: block;
	text-align: center;
	max-width: 100%;
	object-fit: contain;
}

.available-games {
	display: flex;
	flex-flow: column;
}

.playable,
.other-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 50%;
}

.other-info {
	align-items: flex-start;
}

.other-info h4 {
	margin-bottom: 0;
}

.disabled {
	padding: 5px;
	border: 2px #000 solid;
	border-radius: 12px;
}

::-moz-placeholder,
::-webkit-input-placeholder {
	overflow-wrap: anywhere;
}

@media (min-width: 768px) {
	.available-games {
		flex-flow: row wrap;
		padding: 0 15px;
	}
}
</style>
